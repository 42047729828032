/*!
 * Juiced (http://juicedcss.com)
 * Copyright 2015 Joey Lea
 * Licensed under MIT (https://github.com/ovdojoey/Juiced/blob/master/LICENSE)
*/



/* ----------------------------
    Juiced.scss Structure 

    1. Variables (via @import)
      1.A Colors
      1.B Screen sizes
    
    2. Resets
      2.A box-sizing + margin + padding
      2.B body font sizes
      2.C heading tags
      2.D a tags
      2.E pre
      2.F blockquote
      2.G p

    3. Components
      3.A alerts
      3.B buttons
      3.C cards
      3.D input and forms

    4. Helper Classes
    5. Grid
    6. Media Queries

   ---------------------------*/


/* 1. @import _variables.scss */

@import "variables";

/* 2. Resets  */

@import "resets";

/* 3. Components  */

@import "components";

/* 4. Helper Classes  */

@import "helpers";

/* 5. Grid  */

@import "grid";

/* 5. Media Queries  */

@import "breakpoints";



