@font-face {
    font-family: 'SpeedeeCd';
    src: url('../assets/fonts/SpeedeeCd_A_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SpeedeeCd';
    src: url('../assets/fonts/SpeedeeCd_A_Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Speedee';
    src: url('../assets/fonts/Speedee_A_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Speedee';
    src: url('../assets/fonts/Speedee_A_Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
